import { Stack, Text } from '@mantine/core';
import { Alert, Link } from '@edt-monorepo/shared/ui';
import { TakeHomeBreakdownTable } from './TakeHomeBreakdownTable';

export const TakeHomeResult = () => {
  return (
    <Stack gap="xs">
      <Alert variant="info">
        <Text>
          This calculator is a work in progress. Got ideas?{' '}
          <Link
            href="https://edtools.featurebase.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            We&apos;d love to hear them!
          </Link>
        </Text>
      </Alert>
      <TakeHomeBreakdownTable />
    </Stack>
  );
};
