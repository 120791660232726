import * as z from 'zod';
import { TAX_REGIONS, TAX_YEARS } from '@edt-monorepo/checktax/schema';

export const formSchema = z.object({
  income: z
    .number({
      required_error: 'Please provide your income',
      invalid_type_error: 'Please provide your income',
    })
    .nonnegative(),
  taxYear: z.enum(TAX_YEARS, {
    required_error: 'Please select a tax year',
    invalid_type_error: 'Please select a tax year',
  }),
  taxRegion: z.enum(TAX_REGIONS, {
    required_error: 'Please select a tax region',
    invalid_type_error: 'Please select a tax region',
  }),
});

export type TakeHomeCalculatorFormSchema = z.infer<typeof formSchema>;
