import {
  CurrencyFormatter,
  ExpandableDataTable,
  RowStructure,
} from '@edt-monorepo/shared/ui';
import { DataTableColumn } from 'mantine-datatable';
import { Entry } from '@edt-monorepo/checktax/schema';
import { percentFormatter } from '@edt-monorepo/shared/utils';
import { useMemo } from 'react';
import { useTakeHomeResult } from '../useTakeHomeResult';

// TODO: get TS to infer all nested id values
const rowIdToSummaryCellBorderColor: Record<string, string> = {
  totalIncome: 'var(--mantine-color-gray-7)',
  'totalIncome.salary': 'var(--mantine-color-gray-4)',
  taxFreeAllowance: 'var(--mantine-color-blue-7)',
  'taxFreeAllowance.personalAllowance': 'var(--mantine-color-blue-4)',
  incomeTax: 'var(--mantine-color-red-7)',
  'incomeTax.basicRate': 'var(--mantine-color-red-4)',
  'incomeTax.higherRate': 'var(--mantine-color-red-4)',
  'incomeTax.additionalRate': 'var(--mantine-color-red-4)',
  nationalInsurance: 'var(--mantine-color-orange-7)',
  'nationalInsurance.8': 'var(--mantine-color-orange-4)',
  'nationalInsurance.2': 'var(--mantine-color-orange-4)',
  takeHome: 'var(--mantine-color-green-7)',
};

export const TakeHomeBreakdownTable = () => {
  const { calculation } = useTakeHomeResult();

  const columns: DataTableColumn<Entry>[] = useMemo(
    () => [
      {
        accessor: 'label',
        title: 'Summary',
        width: '0%',
        textAlign: 'left',
        cellsStyle: ({ id }) => {
          console.log({ id });
          return {
            borderLeft: `4px solid ${rowIdToSummaryCellBorderColor[id]}`,
          };
        },
        render: ({ label }) => label,
      },
      {
        accessor: 'percentage',
        title: '%',
        width: 65,
        render: ({ percentage }) => percentFormatter(percentage),
      },
      {
        accessor: 'yearly',
        title: 'Yearly',
        render: ({ yearly }) => (
          <CurrencyFormatter value={yearly} decimalScale={2} />
        ),
      },
      {
        accessor: 'monthly',
        title: 'Monthly',
        render: ({ monthly }) => (
          <CurrencyFormatter value={monthly} decimalScale={2} />
        ),
      },
      {
        accessor: 'weekly',
        title: 'Weekly',
        render: ({ weekly }) => (
          <CurrencyFormatter value={weekly} decimalScale={2} />
        ),
      },
      {
        accessor: 'daily',
        title: 'Daily',
        render: ({ daily }) => (
          <CurrencyFormatter value={daily} decimalScale={2} />
        ),
      },
    ],
    []
  );

  return (
    <ExpandableDataTable<RowStructure & Entry>
      allowMultipleExpanded
      allowToggleAll
      columns={columns}
      records={[
        {
          row: {
            expandable: { label: 'total income breakdown' },
            ...calculation.income.total,
          },
          subRows: calculation.income.breakdown.map((r) => ({
            ...r,
          })),
        },
        {
          row: {
            expandable: { label: 'tax-free allowance breakdown' },
            ...calculation.allowance.total,
          },
          subRows: calculation.allowance.breakdown.map((r) => ({
            ...r,
          })),
        },
        {
          row: {
            expandable: { label: 'income tax breakdown' },
            ...calculation.deductions.incomeTax.total,
          },
          subRows: calculation.deductions.incomeTax.breakdown.map((r) => ({
            ...r,
          })),
        },
        {
          row: {
            expandable: { label: 'national insurance breakdown' },
            ...calculation.deductions.nationalInsurance.total,
          },
          subRows: calculation.deductions.nationalInsurance.breakdown.map(
            (r) => ({
              ...r,
            })
          ),
        },
        {
          row: { ...calculation.takeHome },
          subRows: [],
        },
      ]}
    />
  );
};
