import { CalculationInputAndCalculationGeneric } from '@edt-monorepo/shared/ui';
import { TakeHomeCalculatorFormSchema, formSchema } from './form-schema';
import { formValuesToInput } from './form-values-to-input';
import { formValuesFromCookie } from './form-values-from-cookie';
import { directTakeHomeCalculation } from '@edt-monorepo/checktax/services/calculator';
import { COOKIE_CHECKTAX_TAKEHOMECALCULATOR } from '@edt-monorepo/checktax/utils';
import { TakeHomeCalculator } from './TakeHomeCalculator';
import { TakeHomeResult } from './TakeHomeResult';
import {
  TAX_YEARS,
  TakeHomeCalculation,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { head } from 'ramda';
import { Button } from '@mantine/core';

export const TakeHomeCalculatorAndResult = () => {
  return (
    <CalculationInputAndCalculationGeneric<
      TakeHomeCalculatorFormSchema,
      TakeHomeCalculationInput,
      TakeHomeCalculation
    >
      id="take-home"
      logCalculationInputKey="takeHomeInput"
      logCalculationKey="takeHomeResult"
      calculationInputNode={<TakeHomeCalculator />}
      submitNode={(loading) => (
        <Button type="submit" loading={loading}>
          Calculate
        </Button>
      )}
      calculationNode={<TakeHomeResult />}
      formSchema={formSchema}
      defaultValues={{ taxRegion: 'ENGLAND', taxYear: head(TAX_YEARS) }}
      formValuesToInput={formValuesToInput}
      workerFunc={directTakeHomeCalculation}
      autoTriggerSubmit
      cookieStorage={{
        key: COOKIE_CHECKTAX_TAKEHOMECALCULATOR,
        formValuesFromCookieParser: formValuesFromCookie,
      }}
    />
  );
};
