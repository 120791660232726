import {
  TakeHomeCalculation,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { calculateTakeHomeCommand } from '../../__application/command/calculate-take-home-command-service';

export const directTakeHomeCalculation = async (
  input: TakeHomeCalculationInput
): Promise<TakeHomeCalculation> => {
  const calculation = await calculateTakeHomeCommand(input);
  return calculation;
};
