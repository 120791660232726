import { TakeHomeCalculatorAndResult } from '@edt-monorepo/checktax/features/take-home-calculator';
import { ReactNode } from 'react';

export const CHECKTAX_CALCULATORS = ['take-home'] as const;

export type CheckTaxCalculator = typeof CHECKTAX_CALCULATORS[number];

export type CheckTaxCalculatorPage = {
  id: CheckTaxCalculator;
  path?: string;
  title: string;
  reactNode: ReactNode;
};

export type CheckTaxCalculatorPages = Record<
  CheckTaxCalculator,
  CheckTaxCalculatorPage
>;

export const checkTaxCalculatorPagesConfig: CheckTaxCalculatorPages = {
  'take-home': {
    id: 'take-home',
    title: 'Calculate your take-home pay',
    reactNode: <TakeHomeCalculatorAndResult />,
  },
  //   dividend: {
  //     id: 'dividend',
  //     calculatorPath: true,
  //     title: 'Calculate your dividends',
  //     reactNode: <TakeHomeCalculatorAndResult />,
  //   },
};
