import {
  TakeHomeCalculation,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { getTaxInfoForTaxYear } from '../../../__application/query/tax-years-info-query-service';
import { calculateAllowance } from './allowance/calculate-allowance';
import { calculateIncomeTax } from './tax/calculate-income-tax';
import { calculateIncome } from './income/calculate-income';
import { calculateNationalInsurance } from './nic/calculate-national-insurance';

export const calculateTakeHome = async (
  input: TakeHomeCalculationInput
): Promise<TakeHomeCalculation> => {
  const taxYearInfo = getTaxInfoForTaxYear(input.taxRegion, input.taxYear);

  const income = calculateIncome(input);
  const allowance = calculateAllowance(input);
  const incomeTax = calculateIncomeTax(input);
  const nationalInsurance = calculateNationalInsurance(input);

  return {
    taxYearInfo,
    income,
    allowance,
    deductions: {
      incomeTax,
      nationalInsurance,
    },
    takeHome: {
      id: 'takeHome',
      label: 'Take home',
      percentage:
        income.total.percentage -
        incomeTax.total.percentage -
        nationalInsurance.total.percentage,
      yearly:
        income.total.yearly -
        incomeTax.total.yearly -
        nationalInsurance.total.yearly,
      monthly:
        income.total.monthly -
        incomeTax.total.monthly -
        nationalInsurance.total.monthly,
      weekly:
        income.total.weekly -
        incomeTax.total.weekly -
        nationalInsurance.total.weekly,
      daily:
        income.total.daily -
        incomeTax.total.daily -
        nationalInsurance.total.daily,
    },
  };
};
