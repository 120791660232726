import { TaxRegion, TaxYear, TaxYearInfo } from '@edt-monorepo/checktax/schema';
import { TAX_YEARS_INFO } from './data/tax-years-info';

export const getTaxInfoForTaxYearFromRepository = (
  taxRegion: TaxRegion,
  taxYear: TaxYear
): TaxYearInfo => {
  const taxRegionTaxYearsInfo = TAX_YEARS_INFO[taxRegion];
  const taxYearInfo = taxRegionTaxYearsInfo[taxYear];
  return taxYearInfo;
};
