import {
  TakeHomeCalculation,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { calculateTakeHome } from '../../_domain/service/calculate-take-home/calculate-take-home';

export const calculateTakeHomeCommand = async (
  input: TakeHomeCalculationInput
): Promise<TakeHomeCalculation> => {
  const calculation = await calculateTakeHome(input);
  return calculation;
};
