import {
  TakeHomeCalculationInput,
  TotalAndBreakdown,
} from '@edt-monorepo/checktax/schema';

export const calculateIncome = (
  input: TakeHomeCalculationInput
): TotalAndBreakdown => {
  const daysInYear = 365;

  return {
    total: {
      id: 'totalIncome',
      label: 'Total income',
      percentage: 100,
      yearly: input.income,
      monthly: input.income / 12,
      weekly: input.income / 52,
      daily: input.income / daysInYear,
    },
    breakdown: [
      {
        id: 'totalIncome.salary',
        label: 'Salary',
        percentage: 100,
        yearly: input.income,
        monthly: input.income / 12,
        weekly: input.income / 52,
        daily: input.income / daysInYear,
      },
    ],
  };
};
