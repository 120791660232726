import {
  TakeHomeCalculation,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { useCalculationInputAndCalculationGenericContext } from '@edt-monorepo/shared/ui';

export const useTakeHomeResult = () => {
  return useCalculationInputAndCalculationGenericContext<
    TakeHomeCalculationInput,
    TakeHomeCalculation
  >();
};
