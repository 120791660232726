import { TaxYearInfo } from '@edt-monorepo/checktax/schema';

export type CalculateTaxFreeAllowanceReturn = {
  taxFreeAllowance: number;
  taxFreeAllowancePercentage: number;
  totalTaxable: number;
  totalTaxablePercentage: number;
};

export const calculateTaxFreeAllowance = (
  income: number,
  taxYearInfo: TaxYearInfo
): CalculateTaxFreeAllowanceReturn => {
  const {
    taxAllowance: {
      personalAllowance: {
        allowance,
        allowanceTaperThreshold,
        allowanceTaperReductionRate,
      },
    },
  } = taxYearInfo;

  if (income <= allowance) {
    return {
      taxFreeAllowance: allowance,
      taxFreeAllowancePercentage: 100,
      totalTaxable: 0,
      totalTaxablePercentage: 0,
    };
  }

  if (income > 100000) {
    if (income >= 100000 + allowance * allowanceTaperReductionRate) {
      return {
        taxFreeAllowance: 0,
        taxFreeAllowancePercentage: 0,
        totalTaxable: income,
        totalTaxablePercentage: 100,
      };
    }

    const amountOverAllowanceReductionThreshold =
      income - allowanceTaperThreshold;
    const amountOverAllowanceReductionThresholdHalved =
      amountOverAllowanceReductionThreshold / allowanceTaperReductionRate;

    return {
      taxFreeAllowance: allowance - amountOverAllowanceReductionThresholdHalved,
      taxFreeAllowancePercentage:
        ((allowance - amountOverAllowanceReductionThresholdHalved) / income) *
        100,
      totalTaxable: income - amountOverAllowanceReductionThresholdHalved,
      totalTaxablePercentage:
        ((income - (allowance - amountOverAllowanceReductionThresholdHalved)) /
          income) *
        100,
    };
  }

  return {
    taxFreeAllowance: allowance,
    taxFreeAllowancePercentage: (allowance / income) * 100,
    totalTaxable: income - allowance,
    totalTaxablePercentage: ((income - allowance) / income) * 100,
  };
};
