import { TaxRegion, TaxYear, TaxYearInfo } from '@edt-monorepo/checktax/schema';

import { ENGLAND_TAX_YEARS_INFO } from './england';

type TaxYearsInfo = {
  [key in TaxRegion]: Record<TaxYear, TaxYearInfo>;
};

// https://www.gov.uk/guidance/rates-and-thresholds-for-employers-2024-to-2025
// https://www.gov.uk/government/collections/how-to-manually-check-your-payroll-calculations

export const TAX_YEARS_INFO: TaxYearsInfo = {
  ENGLAND: ENGLAND_TAX_YEARS_INFO,
};
