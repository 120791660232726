import styled from 'styled-components';
import { IncomeInput, Paper, mq } from '@edt-monorepo/shared/ui';
import { TaxRegionSelect, TaxYearSelect } from '@edt-monorepo/checktax/ui';
import { Stack } from '@mantine/core';

export const TakeHomeCalculator = () => {
  return (
    <Wrapper>
      <Paper>
        <Stack>
          <TaxRegionSelect />
          <TaxYearSelect />
          <IncomeInput />
        </Stack>
      </Paper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .mantine-InputWrapper-root {
    display: grid;
    align-items: center;
    ${mq({
      gridTemplate: [
        'auto auto / 1fr',
        '1fr / 1fr 2fr',
        null,
        'auto auto / 1fr',
      ],
    })}
  }
`;
