import {
  TotalAndBreakdown,
  Breakdown,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { getTaxInfoForTaxYear } from '../../../../__application/query/tax-years-info-query-service';
import { percentFormatter } from '@edt-monorepo/shared/utils';

export const calculateNationalInsurance = (
  input: TakeHomeCalculationInput
): TotalAndBreakdown => {
  const taxYearInfo = getTaxInfoForTaxYear(input.taxRegion, input.taxYear);

  const inputMonthly = input.income / 12;

  // TODO calculate weeks and days based on tax year
  const daysInYear = 365;

  const applicableRates =
    taxYearInfo.nationalInsurance.employeeRates.monthly.filter(
      (band) => inputMonthly >= band.from
    );

  const insuranceRates: Breakdown = applicableRates.map((rate, index) => {
    const nextBandUp = applicableRates[index + 1];

    const amountToTax = nextBandUp
      ? nextBandUp.from - 1 - rate.from
      : inputMonthly - rate.from;
    const amountTaxed = amountToTax * (rate.rate / 100);

    return {
      id: `nationalInsurance.${rate.rate}`,
      label: `${percentFormatter(rate.rate, 0)} rate`,
      percentage: (amountTaxed / inputMonthly) * 100,
      yearly: amountTaxed * 12,
      monthly: amountTaxed,
      weekly: amountTaxed / 52,
      daily: amountTaxed / daysInYear,
    };
  });

  return {
    total: {
      id: 'nationalInsurance',
      label: 'National insurance',
      ...insuranceRates.reduce(
        (acc, rate) => ({
          percentage: acc.percentage + rate.percentage,
          yearly: acc.yearly + rate.yearly,
          monthly: acc.monthly + rate.monthly,
          weekly: acc.weekly + rate.weekly,
          daily: acc.daily + rate.daily,
        }),
        {
          percentage: 0,
          yearly: 0,
          monthly: 0,
          weekly: 0,
          daily: 0,
        }
      ),
    },
    breakdown: insuranceRates,
  };
};
