import { TakeHomeCalculationInput } from '@edt-monorepo/checktax/schema';
import { TakeHomeCalculatorFormSchema } from './form-schema';

export const formValuesToInput = (
  formValues: TakeHomeCalculatorFormSchema
): TakeHomeCalculationInput => {
  const calculationInput: TakeHomeCalculationInput = {
    income: formValues.income,
    taxYear: formValues.taxYear,
    taxRegion: formValues.taxRegion,
  };

  return calculationInput;
};
