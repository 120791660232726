import {
  TakeHomeCalculation,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { calculateTaxFreeAllowance } from './calculate-tax-free-allowance';
import { getTaxInfoForTaxYear } from '../../../../__application/query/tax-years-info-query-service';

export const calculateAllowance = (
  input: TakeHomeCalculationInput
): TakeHomeCalculation['allowance'] => {
  const taxYearInfo = getTaxInfoForTaxYear(input.taxRegion, input.taxYear);

  const {
    taxFreeAllowance,
    taxFreeAllowancePercentage,
    totalTaxable,
    totalTaxablePercentage,
  } = calculateTaxFreeAllowance(input.income, taxYearInfo);

  // TODO calculate weeks and days based on tax year
  const daysInYear = 365;

  return {
    total: {
      id: 'taxFreeAllowance',
      label: 'Tax-free allowance',
      percentage: taxFreeAllowancePercentage,
      yearly: taxFreeAllowance,
      monthly: taxFreeAllowance / 12,
      weekly: taxFreeAllowance / 52,
      daily: taxFreeAllowance / daysInYear,
    },
    breakdown: [
      {
        id: 'taxFreeAllowance.personalAllowance',
        label: 'Personal allowance',
        percentage: taxFreeAllowancePercentage,
        yearly: taxFreeAllowance,
        monthly: taxFreeAllowance / 12,
        weekly: taxFreeAllowance / 52,
        daily: taxFreeAllowance / daysInYear,
      },
      // TODO show total taxable
      // {
      //   label: 'Total taxable',
      //   percentage: totalTaxablePercentage,
      //   yearly: totalTaxable,
      //   monthly: totalTaxable / 12,
      //   weekly: totalTaxable / 52,
      //   daily: totalTaxable / daysInYear,
      // },
    ],
  };
};
