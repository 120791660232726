import {
  TotalAndBreakdown,
  Breakdown,
  TakeHomeCalculationInput,
} from '@edt-monorepo/checktax/schema';
import { getTaxInfoForTaxYear } from '../../../../__application/query/tax-years-info-query-service';
import { camelise, percentFormatter } from '@edt-monorepo/shared/utils';

export const calculateIncomeTax = (
  input: TakeHomeCalculationInput
): TotalAndBreakdown => {
  const taxYearInfo = getTaxInfoForTaxYear(input.taxRegion, input.taxYear);

  // TODO calculate weeks and days based on tax year
  const daysInYear = 365;

  const applicableBands = taxYearInfo.incomeTax.filter(
    (band) => input.income >= band.from
  );

  const taxesAtEachBand: Breakdown = applicableBands.map((band, index) => {
    const nextBandUp = applicableBands[index + 1];

    const amountToTax = nextBandUp
      ? nextBandUp.from - 1 - band.from
      : input.income - band.from;
    const amountTaxed = amountToTax * (band.rate / 100);

    console.log({ band, nextBandUp, amountToTax, amountTaxed });

    return {
      id: `incomeTax.${camelise(band.name)}`,
      label: `${band.name} (${percentFormatter(band.rate, 0)})`,
      percentage: (amountTaxed / input.income) * 100,
      yearly: amountTaxed,
      monthly: amountTaxed / 12,
      weekly: amountTaxed / 52,
      daily: amountTaxed / daysInYear,
    };
  });

  return {
    total: {
      id: 'incomeTax',
      label: 'Income tax',
      ...taxesAtEachBand.reduce(
        (acc, band) => ({
          percentage: acc.percentage + band.percentage,
          yearly: acc.yearly + band.yearly,
          monthly: acc.monthly + band.monthly,
          weekly: acc.weekly + band.weekly,
          daily: acc.daily + band.daily,
        }),
        {
          percentage: 0,
          yearly: 0,
          monthly: 0,
          weekly: 0,
          daily: 0,
        }
      ),
    },
    breakdown: taxesAtEachBand,
  };
};
