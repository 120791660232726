import { TaxYear, TaxYearInfo } from '@edt-monorepo/checktax/schema';

export const ENGLAND_TAX_YEARS_INFO: Record<TaxYear, TaxYearInfo> = {
  '2024/25': {
    taxAllowance: {
      personalAllowance: {
        allowance: 12570,
        allowanceTaperThreshold: 100000,
        allowanceTaperReductionRate: 2,
      },
    },
    incomeTax: [
      { from: 12571, rate: 20, name: 'Basic rate' },
      { from: 50271, rate: 40, name: 'Higher rate' },
      { from: 125141, rate: 45, name: 'Additional rate' },
    ],
    // Class 1, A only atm
    nationalInsurance: {
      employeeRates: {
        monthly: [
          { from: 1048, rate: 8 },
          { from: 4190, rate: 2 },
        ],
      },
    },
  },
};
