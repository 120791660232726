import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { equals } from 'ramda';
import { FullBleed } from '@edt-monorepo/shared/ui';
import { Stack, Title } from '@mantine/core';
import {
  CheckTaxCalculator,
  checkTaxCalculatorPagesConfig,
} from '@edt-monorepo/checktax/config';

type CheckTaxCalculatorDynamicPageProps = {
  checktaxCalculatorId: CheckTaxCalculator;
};

export default function CheckTaxCalculatorDynamicPage({
  checktaxCalculatorId,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const checktaxCalculatorPage =
    checkTaxCalculatorPagesConfig[checktaxCalculatorId];

  return (
    <FullBleed>
      <Stack>
        <Title order={1}>{checktaxCalculatorPage.title}</Title>
        {checktaxCalculatorPage.reactNode}
      </Stack>
    </FullBleed>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [
      ...Object.values(checkTaxCalculatorPagesConfig).map((c) => ({
        params: {
          calculator: c.path ? ['calculator', c.path] : undefined,
        },
      })),
    ],
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<
  CheckTaxCalculatorDynamicPageProps
> = async (context) => {
  if (context.params) {
    const page = Object.values(checkTaxCalculatorPagesConfig).find(
      (c) =>
        c.path && equals(['calculator', c.path], context?.params?.calculator)
    );
    if (page) {
      return { props: { checktaxCalculatorId: page.id } };
    }
  }

  return {
    props: {
      checktaxCalculatorId: 'take-home',
    },
  };
};
